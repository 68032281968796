import { Result } from "@/assets/script/model/main.model"
import httpUtil from "@/assets/script/utils/http.util"

export class ExhibitionService {
  static openRealtime(
    bedId: string,
    mainBoardId: string,
    qos = 0
  ): Promise<Result<boolean>> {
    return httpUtil.post(
      "/exhibition/openRealtime",
      {
        bedId,
        mainBoardId,
        qos
      },
      "form"
    )
  }

  static getCode(
    bedId: string,
    mainBoardId: string,
    addressCode: string
  ): Promise<Result<string>> {
    return httpUtil.get("/exhibition/getCode", {
      bedId,
      mainBoardId,
      addressCode
    })
  }

  static flashWeightData(
    bedId: string,
    mainBoardId: string
  ): Promise<Result<string>> {
    return httpUtil.post(
      "/exhibition/flashWeightData",
      {
        bedId,
        mainBoardId
      },
      "form"
    )
  }
}
