
import { ref, onMounted, readonly } from "vue"
import { Toast } from "vant"
import { ExhibitionService } from "@/assets/script/service/exhibition.service"
import { localGet } from "@/assets/script/utils/storage.util"
import { COUNT_VIEW_MODEL_KEY } from "@/assets/script/constant/config.constant"
import { ExpoConfigViewModel } from "@/assets/script/model/main.model"

export default {
  setup() {
    const url = ref("")
    const loading = ref(false)
    const props = readonly(
      localGet(COUNT_VIEW_MODEL_KEY, new ExpoConfigViewModel())
    )
    const getQrCode = async () => {
      if (loading.value) {
        return
      }
      loading.value = true
      const toast = Toast.loading({
        message: "体重获取中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0
      })
      const result = await ExhibitionService.getCode(
        props.bedId,
        props.mainBoardId,
        props.addressCode
      )
      url.value = result.data!!
      loading.value = false
      toast.clear()
    }
    onMounted(() => {
      getQrCode()
    })
    return {
      url,
      loading,
      getQrCode
    }
  }
}
